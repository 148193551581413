@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";

@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/jquery-ui-themes/themes/smoothness/jquery-ui.min.css";

@import "mixins";
@import "variables";
@import "fonts";

@import "cards";
@import "menu";
@import "single";

img {
  max-width: 100%;
}

html, body {
  font-family: $font-default;
  color: $col-font;
  background-color: $col-bkg
}

/* Layout */
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .7);
  display: none;
}

header .logo {
  background-image: url('../img/logo.png');
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: top center;
  width: 250px;
  height: 75px;
  margin: auto;
}

header {
  margin-top: 55px;
  margin-bottom: 15px;
}

.top-rider {
  position: fixed;
  height: 40px;
  width: 100%;
  top: 0;
  right: 0;
  background-color: $col-2;
  border-bottom: 2px solid $col-4;
  z-index: 1000;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  background-color: #000;
}

.top-rider .icon {
  font-size: 20px;
  color: $col-4;
  text-align: center;
  padding: 5px;

  background-color: $col-2;
  border: 1px solid $col-4;

  cursor: pointer;
}

.top-rider .icon:hover {
  opacity: 0.7;
}

.color.basketball {
  background-color: $col-basket;
}

.icon.basketball {
  background-image: url('../img/basketball_icon.png');
}

.color.college-basketball {
  background-color: $col-college-basket;
}

.icon.college-basketball {
  background-image: url('../img/basketball_icon.png');
}

.color.football {
  background-color: $col-foot;
}

.icon.football {
  background-image: url('../img/football_icon.png');
}

.color.college-football {
  background-color: $col-college-foot;
}

.icon.college-football {
  background-image: url('../img/football_icon.png');
}

.color.baseball {
  background-color: $col-base;
}

.icon.baseball {
  background-image: url('../img/baseball_icon.png');
}

.color.hockey {
  background-color: $col-hockey;
}

.icon.hockey {
  background-image: url('../img/hockey_icon.png');
}

.site.winnersandwhiners {
  background-image: url('../img/sites/winners_whiners_logo.png');
}

.site.statsalt {
  background-image: url('../img/sites/statsalt_logo.png');
}

.site.capperspicks {
  background-image: url('../img/sites/cappers_picks_logo.png');
}

.site.sportschatplace {
  background-image: url('../img/sites/sports_chat_logo.png');
}

.site.freepicksandparlays {
  background-image: url('../img/sites/picks_and_parlays_logo.png');
}

.site.bangthebook {
  background-image: url('../img/sites/bang_the_book_logo.png');
}

.site.procappers {
  background-image: url('../img/sites/pro_cappers_logo.png');
}

.site.consensusfreepicks {
  background-image: url('../img/sites/consensus_free_picks_logo.png');
}

.site.touthouse {
  background-image: url('../img/sites/touthouse_logo.png');
}

.site.wonderpunter {
  background-image: url("../img/sites/wonder_punter_logo.png");
}

.site.scoresandstats {
  background-image: url("../img/sites/scores.jpg");
}

.site.ultimatecapper {
  background-image: url("../img/sites/ultimatecapper.png");
}

.site.teamrankings {
  background-image: url("../img/sites/teamrankings.png");
}

.site.oddsshark {
  background-image: url("../img/sites/oddsshark.jpg");
}

.site.getmoresports {
  background-image: url("../img/sites/getmoresports.png");
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .top-rider {
    display: none;
  }

  header {
    position: fixed;
    width: 250px;
    left: 0;
    margin-top: 30px;
    top: 0;
  }
  header .logo {
    width: 225px;
    background-size: 225px;
    margin-bottom: 30px;
  }
  .content.container-fluid {
    margin-left: 260px;
    margin-top: 30px;
    width: 760px
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

  .content.container-fluid {
    margin-left: 260px;
    margin-top: 30px;
    width: 910px
  }

}

@media only screen and (min-width: 1500px) {

  .content.container-fluid {
    margin-left: 260px;
    margin-top: 30px;
    width: 1210px
  }
  .col-xlg-3 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
    float: left;
  }
}
.graphic-device {
    width: 100%;

    .graphic {
        padding-top: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
    }
}

.affiliate-link {
    margin-top:5px;
}

.mt-5 {
  margin-top: 2rem !important;
}

