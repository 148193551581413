#single.container-fluid {

  .inner-container {

    border-radius: 5px;
    padding: 15px;
    background-color: $col-2;
  }
  .header {
    .infobox {
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 15px;

      background-position: right 10px center;
      background-repeat: no-repeat;
      .title {
        background-image: url('../img/trans_VS.png');
        background-repeat: no-repeat;
        background-position: top left;

        text-transform: uppercase;

        .date {
          color: #FFF;
          font-weight: 700;
        }
        h3 {
          padding: 0;
          margin: 0;
          line-height: 1;
          font-weight: 700;
          font-size: 20px;
          padding-left: 55px;
            color: #fff;
        }
      }
    }
    .description {
      padding: 15px;
    }

  }

  h3.sub-header {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
  }
  .sub-header-desc{
    font-size: 1.7em;
    @media only screen and (max-width: 768px)  {
      font-size: 1.5em;
    }
    line-height: 1.15em;
  }
  .articles {
    background-color: $col-3;
    border-radius: 5px;
    .article {

      padding: 5px 10px;
      &:last-of-type {
        padding-bottom: 10px;
      }
      &:first-of-type {
        padding-top: 10px;
      }
      display: flex;
      flex-wrap: wrap;
      .image {
        height: 50px;
        order: 1;
        flex: 1 1;

        border: 1px solid #fff;
        border-radius: 5px 0 0 5px;
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-color: $col-2;

        .logo-overlay {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, .8);
          z-index: 1;

          width: 100%;
          height: 50px;

          .logo {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            background-position: center center;
            background-size: auto auto;
            background-repeat: no-repeat;

            width: 100%;
            height: 50px;

          }
          .sequence {
            position: absolute;
            top: 10px;
            left: 10px;
          }
        }

      }
      .excerpt {
        overflow: hidden;
        padding-top: 15px;
        order: 3;
        flex-grow: 0 0 100%;
        a {
          color: #000;
          display: inline;
          text-decoration: underline;
        }
      }

      a {
        color: #fff;
        display: block;
        order: 2;
        flex: 0 0 125px;
      }
      .button {
        height: 50px;

        border-radius: 0 5px 5px 0;
        position: relative;

        p {
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 700;
          text-align: center;
          padding-top: 15px;

        }
      }
    }
  }
  .desktop-excerpt {
    display: none;
  }
  .mobile-excerpt {
    .excerpts {
      padding: 15px;
      .excerpt {
        padding: 10px 0;
        h5 {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

  }
}

@media only screen and (min-width: 480px) {

  .header {
    display: flex;
    .infobox {
      flex: 0 0 300px;
    }
    .description {
      flex-grow: 1;
    }

  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  #single.container-fluid {
    .mobile-excerpt {
      display: none;
    }
    .desktop-excerpt {
      display: block;
    }
    .articles {
      .article {
        flex-wrap: nowrap;
        .image {
          height: 150px;
          order: 1;
          flex: 0 0 150px;

          .logo-overlay {

            width: 150px;
            height: 150px;

            .logo {
              width: 150px;
              height: 150px;

            }

            .sequence {
              display: inline-block;
              position: absolute;
              top: -15px;
              left: 0;
              color: rgba(0, 0, 0, .4);
              font-size: 125px;
              text-align: center;
              width: 150px;
              index: 2;
            }
          }
        }
        .excerpt {
          padding: 0 15px;
          flex-grow: 1;
          order: 2;
        }

        a {
          flex: 0 0 115px;
        }
        .button {
          height: 150px;

          p {
            position: absolute;
            top: 65px;
            left: 10px;
            display: block;
            font-weight: 700;
            padding: 0;

          }
        }
      }
    }
  }

}

.infobox.basketball {
  background-image: url('../img/basketball_icon.png');
}

.infobox.college-basketball {
  background-image: url('../img/basketball_icon.png');
}

.infobox.football {
  background-image: url('../img/football_icon.png');
}

.infobox.college-football {
  background-image: url('../img/football_icon.png');
}

.infobox.baseball {
  background-image: url('../img/baseball_icon.png');
}

.infobox.hockey {
  background-image: url('../img/hockey_icon.png');
}
