
nav {
	display:none;
	position:absolute;
	top:0px;
	left:0;
	z-index:1000;
	width:100%;
}

nav ul {
	list-style:none;
	padding:0;
	margin:0;
}

nav ul li 
{
	display:block;
	width:100%;
	background:$col-4;
	cursor:pointer;

}

nav ul li a {
	display:block;
	color:#fff;
	padding:10px 5px;
	font-weight:700;
}
nav ul li a:hover
{
	color:#fff;
}

nav ul li ul li a:hover
{
	color:$col-font;
}
nav ul li ul li:hover
{
	background-color:$col-3;
}

nav ul li ul li
{	
	padding:5px;
	background:$col-2;
	border-bottom:1px solid $col-4;
}
nav ul li ul li a 
{	
	color:$col-font;
	font-weight:500;
}

nav .static-menu ul{
	background-color:$col-3;
	width:100%;
	height:50px;
}

nav .static-menu li {
	float:left;
	padding:5px 16px;
	width:auto;
	background-color:$col-3;
}
nav .static-menu li:hover
{
	background:$col-4;
}
nav .static-menu p {
	display:block;
	width:100%;
	text-align:center;
	padding:5px;
	clear:both;
}

#close-menu{
	position:fixed;
	bottom:15px;
	right:15px;
	background-color:#fff;
	border:1px solid #000;
	border-radius:5px;
	p{padding:10px;margin:0;line-height:1;}
	&.d-none {display:none;}
}


/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {


	nav {
		display:block;
		position:relative;
		top:0;
		left:0;
		width:250px;
		max-width:250px;
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
nav .static-menu ul{
	width:250px;
}

}


@media only screen and (min-width : 1500px) {

}