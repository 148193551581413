/* Cards */

.card {
  border-radius: 10px;
  background-color: $col-2;
  padding: 8px;
  width: 284px;
  margin: auto;
  margin-bottom: 15px;
}

.card .image {
  border-radius: 10px;
  border: solid 2px #000;
  background-position: 50% 10%;
  background-size: cover;
  position: absolute;
  z-index: 2;
  width: 267px;
  height: 150px;
}

.card .title-section {
  position: relative;
  margin-top: 135px;
  background-repeat: no-repeat;
  background-position: center right;

  a {
    color: inherit;
  }
}

.card .title-section .title {
  width: 210px;
  float: left;
  padding: 20px 5px 5px;
  border-radius: 10px;

  background-image: url('../img/trans_VS.png');
  background-repeat: no-repeat;
  background-position: 155px center;
}

.card .title-section .pretitle {
  color: #fff;
  text-transform: uppercase;
}

.card .title-section .date {
  color: #fff;
}

.card .title-section .title h3 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.card .button {
  margin: 5px 0px;
  position: relative;
  cursor: pointer;
}

.card .button .inside {
  width: 220px;
  background-color: $col-3;
  border: 2px solid $col-4;
  border-radius: 5px;
  height: 55px;
}

.card .button .inside .view {
  float: right;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 17px 10px 0 0;
  margin: 0;
  color: $col-font;
}

.card .button .arrow {
  height: 55px;
  width: 53px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 16px;
}

.card .button .arrow span {
  padding: 20px;
}

.card .button .logo {
  height: 45px;
  margin: 5px;
  width: 100px;
  background-repeat: no-repeat;
  background-position: center left;
  float: left;
  background-size: contain;
}

/* Card Colors */

.card.basketball .title-section .title,
.card.basketball .button .arrow,
nav li.basketball {
  background-color: $col-basket;
}

.card.basketball .title-section {
  background-image: url('../img/basketball_icon.png');
}

.card.college-basketball .title-section .title,
.card.college-basketball .button .arrow,
nav li.college-basketball {
  background-color: $col-college-basket;
}

.card.college-basketball .title-section {
  background-image: url('../img/basketball_icon.png');
}

.card.football .title-section .title,
.card.football .button .arrow,
nav li.football {
  background-color: $col-foot;
}

.card.football .title-section {
  background-image: url('../img/football_icon.png');
}

.card.college-football .title-section .title,
.card.college-football .button .arrow,
nav li.college-football {
  background-color: $col-college-foot;
}

.card.college-football .title-section {
  background-image: url('../img/football_icon.png');
}

.card.baseball .title-section .title,
.card.baseball .button .arrow,
nav li.baseball {
  background-color: $col-base;
}

.card.baseball .title-section {
  background-image: url('../img/baseball_icon.png');
}

.card.hockey .title-section .title,
.card.hockey .button .arrow,
nav li.hockey {
  background-color: $col-hockey;
}

.card.hockey .title-section {
  background-image: url('../img/hockey_icon.png');
}

.card .button.winnersandwhiners .logo {
  background-image: url('../img/sites/winners_whiners_logo.png');
}

.card .button.statsalt .logo {
  background-image: url('../img/sites/statsalt_logo.png');
}

.card .button.capperspicks .logo {
  background-image: url('../img/sites/cappers_picks_logo.png');
}

.card .button.sportschatplace .logo {
  background-image: url('../img/sites/sports_chat_logo.png');
}

.card .button.freepicksandparlays .logo {
  background-image: url('../img/sites/picks_and_parlays_logo.png');
}

.card .button.bangthebook .logo {
  background-image: url('../img/sites/bang_the_book_logo.png');
}

.card .button.procappers .logo {
  background-image: url('../img/sites/pro_cappers_logo.png');
}

.card .button.consensusfreepicks .logo {
  background-image: url('../img/sites/consensus_free_picks_logo.png');
}

.card .button.touthouse .logo {
  background-image: url('../img/sites/touthouse_logo.png');
}

.card .button.wonderpunter .logo {
  background-image: url("../img/sites/wonder_punter_logo.png");
}

.card .button.scoresandstats .logo {
  background-image: url("../img/sites/scores.jpg");
}

.card .button.ultimatecapper .logo {
  background-image: url("../img/sites/ultimatecapper.png");
}

.card .button.teamrankings .logo {
  background-image: url("../img/sites/teamrankings.png");
}

.card .button.oddsshark .logo {
  background-image: url("../img/sites/oddsshark.jpg");
}

.card .button.getmoresports .logo {
  background-image: url("../img/sites/getmoresports.png");
}

.card .button.betdsi .logo {
  background-image: url("../img/bet-dsi-logo-sm.png");
}

.card .button.default .logo .sr-only {
  display: inline;
  width: auto;
  height: auto;
  position: relative;
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1500px) {

}

.button.betdsi {
  .arrow {
    background-color: $col-dsi !important;
  }

  .inside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cta {
      margin-right: 10px;

      p {
        padding: 0;
        margin: 0;

        float: right;
        text-align: right;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        color: $col-dsi;
        span {
          color: $col-font;
          letter-spacing: 0;
        }
      }
    }
  }
}


.button.house-lock {
    .arrow {
        background-color:red !important;
    }
    .inside .view {
        float:none;
        i {
            font-size:250%;
            color:red;

            position:absolute;
            top:10px;
            left:10px;
        }
    }
}
